<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row g-4">
            <ListGroupsCards></ListGroupsCards>

            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <h5>
                            Zarządzanie użytkownikami
                        </h5>
                        <div class="card-header-right" id="box_title_buttons">

                            <button dodaj="" nowy="" @click="openAdd()" class="btn btn-secondary add-new btn-primary"
                                tabindex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasAddUser"><span><i class="bx bx-plus me-0 me-sm-1"></i><span
                                        class="d-none d-sm-inline-block">Dodaj użytkownika</span></span></button>

                        </div>
                    </div>
                    <div class="row mx-2">
                        <!-- <div class="col-md-2">
                            <div class="me-3">
                                <div class="dataTables_length" id="DataTables_Table_0_length"><label><select
                                            name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                            class="form-select">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select></label></div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div
                                class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0">
                                <div id="DataTables_Table_0_filter" class="dataTables_filter"><label><input type="search"
                                            class="form-control" placeholder="Search.."
                                            aria-controls="DataTables_Table_0"></label>
                                </div>
                                <div class="dt-buttons btn-group flex-wrap">
                                    <button class="btn btn-secondary add-new btn-primary" tabindex="0"
                                        aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasAddUser"><span><i
                                                class="bx bx-plus me-0 me-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New User</span></span></button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">

                            <table class="datatables-users table border-top dataTable no-footer dtr-column">
                                <thead>
                                    <tr>
                                        <th>
                                            Imię i nazwisko:
                                        </th>
                                        <th>
                                            Grupa uprawnień:
                                        </th>
                                        <th>
                                            status
                                        </th>
                                        <th>
                                            Akcje:
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="odd" v-for="item, index in items" :key="index">
                                        <td class="sorting_1">
                                            <div class="d-flex justify-content-start align-items-center user-name">
                                                <MagicoAvatar class="me-2" :user_id="item.id"></MagicoAvatar>
                                                <div class="d-flex flex-column"><router-link :to="'user/' + item.id"
                                                        class="text-body text-truncate"><span class="fw-semibold">
                                                            {{ item.name }}</span></router-link>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {{ item.email }}
                                        </td>
                                        <td><span class="text-truncate d-flex align-items-center"><span
                                                    class="badge badge-center rounded-pill bg-label-primary w-px-30 h-px-30 me-2"><i
                                                        class="bx bx-pie-chart-alt bx-xs"></i></span>
                                                {{ item.groups[0] ? item.groups[0] : '--' }}
                                            </span></td>
                                        <td><span class="badge bg-label-success">Aktywny</span></td>
                                        <td class="text-end"><router-link :to="'user/' + item.id"
                                                class="btn btn-outline-secondary p-2 btn-icon"><i
                                                    class="bx bx-show-alt"></i></router-link></td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal class="b-modal-top" :no-close-on-backdrop="true" scrollable="" size="xl" v-model="edit.modal">
            <div slot="modal-title">
                Edycja użytkownika:
            </div>
            <div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Nazwa użytkownika:
                    </label>
                    <input class="form-control" type="text" v-model="edit.item.username" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Imię i Nazwisko:
                    </label>
                    <input class="form-control" type="text" v-model="edit.item.name" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Email:
                    </label>
                    <input class="form-control" type="text" v-model="edit.item.email" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        phone_number:
                    </label>
                    <input class="form-control" type="text" v-model="edit.item.phone_number" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Grupa uprawnień:
                    </label>
                    <select multiple="multiple" class="form-control" v-model="edit.item.groups">
                        <option v-bind:value="index" v-for="option, index in $store.state.slownik_groups" :key="index">
                            {{ option.title }}
                        </option>
                    </select>
                    <div class="form-group mb-1">
                        <label class=" col-form-label">
                            Dostęp do panelu:
                        </label>
                        <select class="form-control" v-model="edit.item.panel_access">
                            <option value="0">
                                Tylko użytkownik storny
                            </option>
                            <option value="1">
                                Użytkownik strony + użytkownik panelu
                            </option>
                        </select>
                    </div>
                    <div class="form-group row mb-2">
                        <label class="col-sm-4 col-form-label">
                            Hasło
                        </label>
                        <div class="col-sm-8">
                            <input class="form-control" type="password" v-model="edit.item.password" />
                        </div>
                    </div>
                    <div class="form-group row mb-2">
                        <label class="col-sm-4 col-form-label">
                            Powtórz hasło
                        </label>
                        <div class="col-sm-8">
                            <input class="form-control" type="password" v-model="edit.item.password2" />
                        </div>
                    </div>
                    <div class="form-group row mb-2">
                        <label class="col-sm-4 col-form-label">
                            Wygeneruj hasło
                        </label>
                        <div class="col-sm-8">
                            <div class="input-group mb-3">
                                <input class="form-control" type="text" v-model="edit.item.generated" />
                                <div class="input-group-append">
                                    <button @click="generatePass(edit.item)" class="btn btn-outline-primary" type="button">
                                        Generuj
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-10">
                            Wyslij nowe hasło na maila
                        </label>
                        <div class="col">
                            <input class="form-control" style="" type="checkbox" v-model="edit.item.sendmail" />
                        </div>
                    </div>
                    <div class="alert alert-danger" v-show="edit.error">
                        <ul>
                            <li v-for="err in edit.error" :key="err">
                                {{ err }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button @click="edit.modal = false" class="btn btn-outline-secondary ">
                        Anuluj
                    </button>
                    <button @click="saveEdit(edit.item)" class="btn btn-primary">
                        Zapisz
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal class="b-modal-top" :no-close-on-backdrop="true" scrollable="" size="xl" v-model="add.modal">
            <div slot="modal-title">
                Dodawanie użytkownika:
            </div>
            <div>
                <!-- <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Nazwa użytkownika:
                    </label>
                    <input class="form-control" type="text" v-model="add.item.username" />
                </div> -->
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Imię i Nazwisko:
                    </label>
                    <input class="form-control" type="text" v-model="add.item.name" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Email:
                    </label>
                    <input class="form-control" type="text" v-model="add.item.email" />
                </div>
                <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Telefon:
                    </label>
                    <input class="form-control" type="phone" v-model="add.item.phone_number" />
                </div>
                <div class="form-group mb-2">
                    <label class=" col-form-label">
                        Grupa uprawnień:
                    </label>
                    
                    <select  class="form-control" v-model="add.item.group">
                        <option v-bind:value="ko" v-for="option, ko in $store.state.slownik_groups" :key="ko">
                            {{ option.title }}
                        </option>
                    </select>
                </div>
                <!-- <div class="form-group mb-1">
                    <label class=" col-form-label">
                        Dostęp do panelu:
                    </label>
                    <select class="form-control" v-model="add.item.panel_access">
                        <option value="0">
                            Tylko użytkownik storny
                        </option>
                        <option value="1">
                            Użytkownik strony + użytkownik panelu
                        </option>
                    </select>
                </div> -->
                <div class="form-group row mb-2">
                    <label class="col-sm-4 col-form-label">
                        Hasło
                    </label>
                    <div class="col-sm-8">
                        <input class="form-control" type="password" v-model="add.item.password" />

                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-sm-4 col-form-label">
                        Powtórz hasło
                    </label>
                    <div class="col-sm-8">
                        <input class="form-control" type="password" v-model="add.item.password2" />
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-sm-4 col-form-label">
                        Wygeneruj hasło
                    </label>
                    <div class="col-sm-8">
                        <div class="input-group mb-3">
                            <input class="form-control" type="text" v-model="add.item.generated" />
                            <div class="input-group-append">
                                <button @click="generatePass(add.item)" class="btn btn-outline-primary" type="button">
                                    Generuj
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group row mb-4">
                    <label class="col-md-10">
                        Wyslij nowe hasło na maila
                    </label>
                    <div class="col">
                        <input class="form-control" style="" type="checkbox" v-model="add.item.sendmail" />
                    </div>
                </div> -->
                <div class="alert alert-danger" v-show="add.error">
                    <ul>
                        <li v-for="err in add.error" :key="err">
                            {{ err }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class=" d-flex justify-content-center ">
                <div class="text-right">
                    <button @click="add.modal = false" class="btn btn-outline-secondary me-4 ">
                        Anuluj
                    </button>
                    <button @click="saveAdd(add.item)" class="btn btn-primary">
                        Zapisz
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal class="b-modal-top" scrollable="" v-model="del.modal">
            <div slot="modal-title">
                Usuwanie użytkownika
            </div>
            <div>
                <p>
                    Czy napewno usunąć
                    <b>
                        {{ del.item.name }}?
                    </b>
                    ?
                </p>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button @click="del.modal = false" class="btn btn-outline-secondary ">
                        Anuluj
                    </button>
                    <button @click="deleteSave(del.item)" class="btn btn-danger">
                        Usuń
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal class="b-modal-top" :no-close-on-backdrop="true" scrollable="" size="xl" v-model="photo.modal" no-footer>
            <div slot="modal-title">
                Wgraj zdjęcie profilowe:
            </div>
            <fileupload :target="uploadTarget" action="POST" class="form-control" v-on:finish="finishUpload"
                v-on:progress="progress" v-on:start="startUpload">
            </fileupload>
            <div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// @ is an alias to /src

import axios from '../../api/axios';
import MagicoModal from '../../components/MagicoModal';
import FileUpload from 'vue-simple-upload/src/FileUpload.vue';
import api from '../../api/api';
import ListGroupsCards from './components/ListGroupsCards.vue';
import MagicoAvatar from '../../components/MagicoAvatar.vue';

export default {
    name: 'UserIndex',
    props: ['type'],
    components: {
        "fileupload": FileUpload,
        "b-modal": MagicoModal,
        ListGroupsCards,
        MagicoAvatar
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            uploadTarget: '/',
            loading: false,
            items: [],
            group_names: {},
            group: [],
            edit: {
                modal: false,
                item: {
                    groups: [],
                },
                error: false
            },
            add: {
                modal: false,
                item: {
                    groups: [],
                    sendmail: false,
                    panel_access: 0,
                    phone_number: '',
                },
                error: false
            },
            del: {
                modal: false,
                item: {}
            },
            photo: {
                modal: false,
                item: {}
            }
        }
    },
    computed: {
        getGroups: function () {
            return this.$store.state.slownik_groups
        }
    },
    methods: {
        openAdd: function () {
            this.add.modal = true;
            this.add.item = {
                sendmail: false,
                panel_access: (this.$props.type == 'panel' ? 1 : 0),
                group: 'user',
                password: '',
                password2: '',
                generated: '',

            };
        },
        loadTable: function () {
            let vm = this;
            axios.get(this.BASE_URL + 'api/v1/user/users?type=' + this.$props.type).then(response => {
                vm.items = response.data.data;
                vm.group_names = {};
                vm.group = response.data.group;
                // response.data.group.forEach(function (val) {
                //     vm.group_names[val.group] = val.name;
                // });
            });
        },
        openEdit: function (item) {
            this.edit.modal = true;
            this.edit.item = item;
            this.edit.item.generated = "";
            this.edit.item.sendmail = false;
            this.edit.error = false;
        },
        saveEdit: function (item) {
            let vm = this;
            axios.put(this.BASE_URL + 'api/v1/user/users/' + item.id, item).then(() => {
                vm.edit.modal = false;
                vm.loadTable();
                //MAGICO_PANEL.alert("success", "Zapisano poprawnie");
            }, (error) => {
                console.log(error);
                this.$toast('Wystąpił błąd')
                vm.loadTable();
                vm.edit.error = error.response.data.validation;
            });
        },
        saveAdd: function (item) {
            item.username = item.email;
            let vm = this;
            
            axios.post(this.BASE_URL + 'api/v1/user/users', item).then(() => {
                vm.add.modal = false;
                vm.loadTable();
                vm.$toast("Zapisano poprawnie");
            }, (error) => {
                console.log(error);
                //MAGICO_PANEL.alert('danger', 'Wystapił błąd');
                vm.loadTable();
                vm.add.error = error.response.data.validation;
            });
        },
        deleteConfirm: function (item) {
            this.del.modal = true;
            this.del.item = item;
        },
        deleteSave: function (item) {
            let vm = this;
            axios.delete(this.BASE_URL + 'api/v1/user/users/' + item.id).then(() => {
                vm.del.modal = false;
                vm.loadTable();
            });
        },
        generatePass: function (item) {
            let pass = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 12);
            item.password = pass;
            item.password2 = pass;
            item.generated = pass;
        },
        openPhoto(item) {
            this.photo.modal = true;
            this.uploadTarget = '';
            this.uploadTarget = 'https://algorytm.serwer.magico.pl/api/api/v1/user/users/image/' + item.id;
        },
        progress(e) { console.log(e) },
        startUpload(e) { console.log(e) },
        finishUpload(e) {
            console.log(e.currentTarget);
            if (e.currentTarget.status != 200) {
                var er = JSON.parse(e.currentTarget.response);
                console.log(er.errors.file);
                //MAGICO_PANEL.alert('danger', er.errors.file)
            } else {
                this.loadTable();
                this.photo.modal = false;
                this.uploadTarget = '';
                //MAGICO_PANEL.alert('success', 'Zdjęcie wgrano pomyślnie!');
            }
        },
    },
    created() {
        this.loadTable();
    }
}
</script>
