<template>
    <div class="row g-4">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="group, index in groups" :key="index">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-2">
                        <h6 class="fw-normal">{{ group.users.length }} {{
                            group.users.length == 1 ? 'użytkownik' : (group.users.length == 2 ? 'użytkownicy' :
                                'użytkowników') }}</h6>
                        <ul class="list-unstyled d-flex align-items-center avatar-group mb-0">
                            <li v-for="user, index2 in group.users" :key="index2" is="MagicoAvatar" :size="28"
                                :user_id="user.id" class="pull-up">
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex justify-content-between align-items-end">
                        <div class="role-heading">
                            <h4 class="mb-1">{{ group.title }}</h4>
                            <!-- <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#addRoleModal"
                                class="role-edit-modal"><small>Edit Role</small></a> -->
                        </div>
                        <!-- <a href="javascript:void(0);" class="text-muted"><i class="bx bx-copy"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';
import MagicoAvatar from '../../../components/MagicoAvatar.vue';

export default {
    data: function () {
        return {
            groups: [],
        };
    },
    created: function () {
        this.getGroups();
    },
    methods: {
        getGroups: function () {
            axios.get("api/v1/user/groups").then((response) => {
                this.groups = response.data.data;
            });
        }
    },
    components: { MagicoAvatar }
}
</script>